import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import Layout from '../../components/Layout';
import {Badge, Box, Button, Flex, List, rem, Space, Tabs, Text, ThemeIcon, Title} from '@mantine/core';
import {IconCircleCheck, IconUser, IconUserPlus} from '@tabler/icons-react';
import {useNavigate} from 'react-router-dom';
import {useIsMobile} from '../../components/Layout/Header/Header';
import {api} from '../../api';
import {urlJoiner} from '../../utils';
import {themeColors} from '../../shared/theme/theme';
import userInfo from '../../store/UserInfo';

const SUBSCRIPTION_COST = 1490;

export const daysUntilSubscriptionEnds = (endDate?: string) => {
    if (!endDate) {
        return 0;
    }

    const currentDate = new Date(); // текущая дата
    const targetDate = new Date(endDate); // дата окончания подписки

    // Вычисляем разницу в миллисекундах между двумя датами
    const differenceInTime = targetDate.getTime() - currentDate.getTime();

    // Переводим миллисекунды в дни
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
}

const ProfilePage = observer<any>(() => {
    const {isAuthed} = userInfo;
  useEffect(() => {
    document.title = 'VideoCensor — Профиль | Автоматически удалить/запикать мат в видео';
  }, []);

  const isMobile = useIsMobile();
  const navigate = useNavigate();

    useEffect(() => {
        if (isAuthed === false) {
            navigate('/');
        }
    }, [navigate, isAuthed]);

  return (
      <Layout title={'Профиль'}>
          <div>
              <Tabs orientation={isMobile ? 'horizontal' : 'vertical'} defaultValue="profile">
                  <Tabs.List>
                      <Tabs.Tab value="profile" leftSection={<IconUser />}>
                          Профиль
                      </Tabs.Tab>
                      <Tabs.Tab value="subscription" leftSection={<IconUserPlus />}>
                          Подписка
                      </Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="profile">
                      <Flex direction={'column'} p={isMobile ? '2rem 0' : '0 2rem'} gap={'1rem'}>
                          <Title order={2}>
                              Профиль
                          </Title>
                          <Box>
                              <Flex gap={'4px'}>
                                  <Text fw={'700'}>Имя:</Text><Text>{userInfo.name}</Text>
                              </Flex>
                              <Flex gap={'4px'}>
                                  <Text fw={'700'}>Электронная почта:</Text><Text>{userInfo.email}</Text>
                              </Flex>
                          </Box>
                      </Flex>
                  </Tabs.Panel>

                  <Tabs.Panel value="subscription">
                      <Flex direction={'column'} p={isMobile ? '2rem 0' : '0 2rem'} gap={'2rem'}>
                          <Title order={2}>
                              Оплата подписки на месяц | {SUBSCRIPTION_COST} рублей
                          </Title>

                          {(userInfo.subscriptionExpiration && daysUntilSubscriptionEnds(userInfo.subscriptionExpiration) > 0) && (
                              <Box>
                                  <Flex direction={'column'} gap={'4px'}>
                                      <Badge size={'lg'} color={themeColors.success}>Ваша подписка оплачена</Badge>
                                      <Badge size={'lg'}
                                             color={themeColors.warning}>до {new Date(userInfo.subscriptionExpiration).toLocaleDateString()} (осталось
                                          дней: {daysUntilSubscriptionEnds(userInfo.subscriptionExpiration)})</Badge>
                                  </Flex>
                              </Box>
                          )}

                          <Box>
                              <Title order={4} mb={'1rem'}>
                                  Что дает мне подписка?
                              </Title>
                              <List
                                  spacing="xs"
                                  size="sm"
                                  center
                                  icon={
                                      <ThemeIcon color="teal" size={24} radius="xl">
                                          <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                      </ThemeIcon>
                                  }
                              >
                                  <List.Item>Увеличение объема загружаемых файлов до 300мб</List.Item>
                                  <List.Item>Обработка файлов без очереди</List.Item>
                                  <List.Item>Настройки с дополнительными возможностями</List.Item>
                                  <List.Item>Приоритетная поддержка</List.Item>
                              </List>
                          </Box>

                          <Button onClick={async () => {
                              const {data} = await api.post(
                                  urlJoiner(process.env.REACT_APP_API, '/subscription'),
                                  {id: userInfo.id},
                              );

                              window.location.replace(data.confirmationUrl);
                          }} size='lg'>
                              {
                                  (userInfo.subscriptionExpiration && daysUntilSubscriptionEnds(userInfo.subscriptionExpiration) > 0) ? (
                                      `Продлить подписку на месяц за ${SUBSCRIPTION_COST} рублей`
                                  ) : (
                                      `Оплатить подписку за ${SUBSCRIPTION_COST} рублей`
                                  )
                              }
                          </Button>
                      </Flex>
                  </Tabs.Panel>
              </Tabs>
              <Space h={'2rem'}/>
              <Flex>
                  <Button variant={'outline'} ml={'auto'} onClick={() => {
                      localStorage.removeItem('token');
                      window.location.reload();
                  }}>
                      Выйти
                  </Button>
              </Flex>
          </div>
      </Layout>
  )
});

export default ProfilePage;
